import { Route, Routes } from "react-router-dom";
import PresalePage from "./pages/PresalePage";
import LandingPage from "./pages/LandingPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import ProfileEditPage from "./pages/profile/ProfileEditPage";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/privacy" element={<PrivacyPage />} />
        <Route exact path="/terms" element={<TermsPage />} />
        <Route exact path="/presale" element={<PresalePage />} />
        <Route exact path="/edit-profile" element={<ProfileEditPage />} />
      </Routes>
    </>
  );
}

export default App;
