import { useEffect, useState } from "react";

function ProgressCap({ cap }) {
    const [status, setStatus] = useState('');
    const [progress, setProgress] = useState("");

    useEffect(() => {
        const totalCap = parseInt(cap);
        if (totalCap < 200000) {
					setProgress((totalCap / 200000) * (100/3));
          setStatus("Below Soft Cap");
        } else if (totalCap >= 200000 && totalCap < 500000) {
					setProgress((((totalCap - 200000) / 300000) * (100/3)) + 33.3);
          setStatus("Soft Cap Reached");
        } else {
					setProgress(((totalCap - 500000) / 500000) * (100 / 3) + 66.6);
          setStatus("Hard Cap Reached");
        }
    }, [cap]);

    return (
      <>
        <h4 className="text-center font-barlow text-2xl font-bold mb-6 text-brown">Status: {status} REACHED</h4>
        <div className="font-dosis relative bg-green-200 rounded-md">
          <div className="w-full absolute h-8 top-0 overflow-x-hidden">
            <div className="h-8 bg-green-500 rounded-md animate-pulse" style={{ width: `${progress}%` }}></div>
          </div>
          <div className="relative grid grid-cols-3">
            <div className="w-full border border-brown h-8 rounded-l-md">
              <div className="ralative mt-8 p-2 text-brown">
                <div className="text-green-700">
                  <h6 className="font-bold text-lg md:text-xl lg:text-2xl font-barlow uppercase">Below Softcap</h6>
                  <span className="font-bold">{`< $200,000`}</span>
                </div>

                <p className="font-bold mt-4 text-xl">Projects</p>
                <ul className="list-disc pl-4 text-lg font-medium">
                  <li>P2E</li>
                  <li>ECO</li>
                </ul>
              </div>
            </div>
            <div className="w-full border border-brown h-8">
              <div className="ralative mt-8 p-2 text-brown">
                <div className="text-green-700">
                  <h6 className="font-bold text-lg md:text-xl lg:text-2xl font-barlow uppercase">Softcap</h6>
                  <span className="font-bold">{`$200,000`}</span>
                </div>

                <p className="font-bold mt-4 text-xl">Projects</p>
                <ul className="list-disc pl-4 text-lg font-medium">
                  <li>P2E</li>
                  <li>ECO</li>
                  <li>Staking</li>
                </ul>
              </div>
            </div>
            <div className="w-full border border-brown h-8 rounded-r-md">
              <div className="ralative mt-8 p-2 text-brown">
                <div className="text-green-700">
                  <h6 className="font-bold text-lg md:text-xl lg:text-2xl font-barlow uppercase">Hardcap</h6>
                  <span className="font-bold">{`$500,000`}</span>
                </div>

                <p className="font-bold mt-4 text-xl">Projects</p>
                <ul className="list-disc pl-4 text-lg font-medium">
                  <li>P2E</li>
                  <li>ECO</li>
                  <li>Staking</li>
                  <li>NFT</li>
                  <li>Farming</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ProgressCap;