import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="w-full bg-primary py-12 text-center text-brown text-lg space-y-4">
      <div className="flex items-center justify-center space-x-7">
        <a href="https://discord.gg/thekitsu" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
          <Icon icon="fa6-brands:discord" className="iconify w-7 h-7" />
        </a>
        <a href="http://twitter.com/thekitsugame" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
          <Icon icon="fa6-brands:twitter" className="iconify w-7 h-7" />
        </a>
        <a href="https://www.youtube.com/channel/UC6JTjrtL7boMP1mmgVOQ9HQ" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
          <Icon icon="fa6-brands:youtube" className="iconify w-7 h-7" />
        </a>
        <a href="http://instagram.com/thekitsugame" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
          <Icon icon="fa6-brands:instagram" className="iconify w-7 h-7" />
        </a>
        <a href="mailto:business@kitsu.one" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
          <Icon icon="fa6-regular:envelope" className="iconify w-7 h-7" />
        </a>
      </div>
      <div className="flex items-center justify-center space-x-5 font-dosis">
        <Link to="/terms" className="inline-block hover:contrast-50">
          Terms & Conditions
        </Link>
        <span>|</span>
        <Link to="/privacy" className="inline-block hover:contrast-50">
          Privacy Policy
        </Link>
        <span>|</span>
        <Link to="/" className="inline-block opacity-60 hover:contrast-50">
          Competition Rules (Coming Soon)
        </Link>
      </div>
      <p>© 2022 Kitsu</p>
    </footer>
  );
}

export default Footer;
