import "@splidejs/react-splide/css/core";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import LayoutBase from "../layouts/LayoutBase";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import ImgPresale from "../assets/images/presale.webp";
import ImgStory from "../assets/images/story.webp";
import ImgLogo from "../assets/images/logo.webp";
import ImgEcosystem from "../assets/images/ecosystem.webp";

import ImgBackgroundAbout from "../assets/images/bg/about.webp";
import ImgBackgroundEcosystem from "../assets/images/bg/ecosystem.webp";
import ImgBackgroundHeader from "../assets/images/bg/header.webp";
import ImgBackgroundPaper from "../assets/images/bg/paper.webp";
import ImgBackgroundRoadmap from "../assets/images/bg/roadmap.webp";
import ImgBackgroundStory from "../assets/images/bg/story.webp";
import ImgBackgroundTeam from "../assets/images/bg/team.webp";
import ImgBackgroundTokenomic from "../assets/images/bg/tokenomic.webp";

import ImgDividerBottomEcosystem from "../assets/images/divider/ecosystem-bottom.webp";
import ImgDividerBottomPaper from "../assets/images/divider/paper-bottom.webp";
import ImgDividerBottomRoadmap from "../assets/images/divider/roadmap-bottom.webp";
import ImgDividerTopRoadmap from "../assets/images/divider/roadmap-top.webp";
import ImgDividerTopTeam from "../assets/images/divider/team-top.webp";

import ImgPaperCover from "../assets/images/paper/cover.webp";
import ImgPaperHolo from "../assets/images/paper/holo.webp";

import ImgRoadmapBgTitle from "../assets/images/roadmap/bg-title.webp";
import ImgRoadmapList from "../assets/images/roadmap/list.webp";
import ImgRoadmapMonth2 from "../assets/images/roadmap/month-2.webp";
import ImgRoadmapPlane from "../assets/images/roadmap/plane.webp";
import ImgRoadmapPlanet1 from "../assets/images/roadmap/planet-1.webp";
import ImgRoadmapPlanet2 from "../assets/images/roadmap/planet-2.webp";
import ImgRoadmapPlanet3 from "../assets/images/roadmap/planet-3.webp";
import ImgRoadmapRoad from "../assets/images/roadmap/road.webp";

import ImgTeamKenshin from "../assets/images/team/kenshin.webp";
import ImgTeamKitsuLover from "../assets/images/team/kitsu-lover.webp";
import ImgTeamKitsuMonster from "../assets/images/team/kitsu-monster.webp";
import ImgTeamKitsuSpirit from "../assets/images/team/kitsu-spirit.webp";
import ImgTeamKitsune from "../assets/images/team/kitsune.webp";
import ImgTeamKitsushi from "../assets/images/team/kitsushi.webp";
import ImgTeamListSingle from "../assets/images/team/single-list.webp";
import ImgTeamListDouble from "../assets/images/team/double-list.webp";

import ImgTokenomicHoloText from "../assets/images/tokenomic/holo-text.webp";
import ImgTokenomicHoloToken from "../assets/images/tokenomic/holo-token.png";

import ImgGamesArrowL from "../assets/images/games/arrow-l.webp";
import ImgGamesArrowR from "../assets/images/games/arrow-r.webp";
import ImgGamesBgSplide from "../assets/images/games/bg-splide.webp";
import ImgGamesBtnPlayStore from "../assets/images/games/btn-play-store.webp";
import ImgGamesPhone from "../assets/images/games/phone.webp";
import ImgGamesSC1 from "../assets/images/games/sc-01.webp";
import ImgGamesSC2 from "../assets/images/games/sc-02.webp";
import ImgGamesSC3 from "../assets/images/games/sc-03.webp";
import ImgGamesSC4 from "../assets/images/games/sc-04.webp";
import ImgGamesSC5 from "../assets/images/games/sc-05.webp";
import ImgGamesSC6 from "../assets/images/games/sc-06.webp";

import ImgTitleAbout from "../assets/images/title/bg-about.webp";
import ImgTitleDownload from "../assets/images/title/bg-download.webp";
import ImgTitleEcosystem from "../assets/images/title/bg-ecosystem.webp";
import ImgTitlePaper from "../assets/images/title/bg-paper.webp";
import ImgTitleRoadmap from "../assets/images/title/bg-roadmap.webp";
import ImgTitleStory from "../assets/images/title/bg-story.webp";
import ImgTitleTeam from "../assets/images/title/bg-team.webp";
import ImgTitleTokenomic from "../assets/images/title/bg-tokenomic.webp";
import ImgTitleTextAbout from "../assets/images/title/text-about.svg";
import ImgTitleTextDownload from "../assets/images/title/text-download.svg";
import ImgTitleTextEcosystem from "../assets/images/title/text-ecosystem.svg";
import ImgTitleTextPaper from "../assets/images/title/text-paper.svg";
import ImgTitleTextRoadmap from "../assets/images/title/text-roadmap.svg";
import ImgTitleTextStory from "../assets/images/title/text-story.svg";
import ImgTitleTextTeam from "../assets/images/title/text-team.svg";
import ImgTitleTextTokenomic from "../assets/images/title/text-tokenomic.svg";
import { Icon } from "@iconify/react";
import ProgressCap from "../components/ProgressCap";

function LandingPage() {
  let [showModal, setShowModal] = useState(true);

  return (
    <LayoutBase>
      <div id="header" className="py-24 w-full bg-cover bg-bottom" style={{ backgroundImage: `url(${ImgBackgroundHeader})` }}>
        <div className="flex justify-center items-center">
          <div className="max-w-lg px-4 text-center">
            <img src={ImgLogo} alt="Logo" className="w-48 md:w-64 mx-auto mb-6" />
            <div data-aos="fade-in" data-aos-delay="200" className="space-y-6">
              <p className="font-dosis text-white text-lg">
                Kitsu Play-and-Earn connects a worldwide community of gamers with highly engaging gameplay. We build an exclusive virtual world where players
                can own, play, and monetize their gameplay experience using Kitsu, the platform's core utility token.
              </p>
              <a
                href="#games"
                className="relative inline-block font-barlow font-medium uppercase text-2xl bg-primary px-16 py-4 rounded-xl border-x-4 border-l-neonblue border-r-neonpink group transition-all hover:contrast-125 hover:border-x-primary hover:-translate-y-1 hover:shadow-xl hover:shadow-neonpink/50"
              >
                <span className="absolute z-0 inset-x-0 -translate-x-px text-neonblue group-hover:-translate-x-0">Download</span>
                <span className="absolute z-0 inset-x-0 translate-x-px text-neonpink group-hover:translate-x-0">Download</span>
                <span className="relative z-10">Download</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="story" className="py-24 w-full bg-primary relative">
        <img src={ImgBackgroundStory} alt="Background" className="w-full absolute inset-x-0 -top-24" />
        <div className="relative max-w-3xl mx-auto px-4 -mt-52">
          <div className="relative">
            <img src={ImgTitleStory} alt="Story" className="w-full mix-blend-screen" />
            <div className="absolute top-20 sm:top-24 md:top-44 w-full">
              <img src={ImgTitleTextStory} alt="Story" className="w-32 sm:w-40 md:w-52 mx-auto" />
            </div>
          </div>

          <img src={ImgStory} alt="Story" className="w-full -mt-12 md:-mt-28" />

          <div className="font-dosis text-lg text-brown text-center space-y-3 mt-8">
            <p>
              There is a kingdom in Kitsu Land, which is far-far away in the east side of the Nihonkoku Nation. All around, you can see magniﬁcent, sparkly
              scenery. And the people of Kitsu are well-known for their hobby, racing.
            </p>
            <p>
              One day, the King held a contest: "Whoever can get and collect three Kitsu Stones shall win an Easter Egg." Finding Kitsu Stones is a huge
              challenge.
            </p>
            <p>
              The sacred Stones—Kitsu Stone of Mind, Kitsu Stone of Body, and Kitsu Stone of Soul could be anywhere, but nobody knows for sure. Their location
              is like a tricky puzzle to unravel.
            </p>
            <p>
              To get it, you must solve the puzzles in the game or those written in the script of the Kitsu Kingdom. The struggle did not end here. After
              getting the Easter Egg, the winner must complete one more quest. Just one ﬁnal test. Anyone who collects three Kitsu Stones and an Easter Egg and
              properly fulﬁlls the ﬁnal quest will win a big reward! The King will present all the wealth of Kitsu Land.
            </p>
            <p>
              The winner are entitled to all existing Kitsu Token that have by developer and the source code of the Kitsu Game. He/ She has the authority to
              change, replace, edit, and delete the game's rules. Kitsu no longer belongs to the developer but has become the community's property.
            </p>
            <p>Solve the puzzle, collect 3 Kitsu Stones, complete the Easter Egg test... and it's ALL YOURS!</p>
          </div>
          <p className="mt-8 text-xl text-brown text-center">- Let’s The Race Begin -</p>

          {/* SOFT CAP */}
          <div className="h-96 py-12">
            <ProgressCap cap={250000} />
          </div>
        </div>
      </div>

      <div id="about" className="w-full bg-brown relative">
        <img src={ImgBackgroundAbout} alt="Background" className="w-full absolute inset-x-0" />
        <div className="relative max-w-2xl mx-auto px-4 -top-12 md:-top-20">
          <div className="relative">
            <img src={ImgTitleAbout} alt="About" className="w-full mix-blend-screen" data-aos="fade-in" />
            <div className="absolute top-20 sm:top-24 md:top-36 w-full">
              <img src={ImgTitleTextAbout} alt="About" className="w-32 sm:w-44 md:w-52 mx-auto" data-aos="zoom-in" data-aos-delay="200" />
            </div>
          </div>

          <div className="font-dosis text-lg text-white text-center space-y-3">
            <p>
              Blockchain technology has emerged as a new trend in cutting-edge technology. And play-and-earn gaming is one of the fastest-growing opportunities
              in the crypto market today.
            </p>
            <p>
              However, the barrier to entry into blockchain-based games is that it is difﬁcult for gamers to ﬁnd suitable games to play. We immersed ourselves
              in the industry to build solutions and homes for the Play-and-earn world with leading strategic partners and our active, engaged, healthy
              community. And our story has begun…
            </p>
            <p>
              We bring KITSU GAME, our newest play-and-earn blockchain gaming project, offering a seamless experience for gamers and developers looking to
              participate in lucrative Play-and-earn opportunities.
            </p>
            <p>We have begun building and growing our community, yet our vision for Kitsu Game is much bigger.</p>
            <p>
              In addition to Play-and-earn Games, KITSU will also focus on NFT projects with exclusive membership beneﬁts to enter the game developer community,
              represent and trade in-game characters and cars, and provide merchandise and utilities to NFT holders.
            </p>
            <p>
              KITSU derives from the Japanese word "Kitsune," which means "fox." We stand by the spirit of the phrase "Yoi Kitsune," which means "Good Fox."
              Through Kitsu Game, we hope this play and earn initiative will bring good things to all of us—investors, developers, and players.
            </p>

            <div>
              <div className="space-y-2 my-8">
                <h4 className="font-bold text-lg">KITSU Token Ecosystem and Use Case</h4>
                <p>
                  KITSU is a utility token built on the Solana (SOL) blockchain protocol, which acts as the native token and medium of exchange in the KITSU
                  ecosystem.
                </p>
              </div>

              <div className="space-y-2 my-8">
                <h4 className="font-bold text-lg">KITSU Ecosystem</h4>
                <p>
                  This ecosystem is key to unlocking more economic opportunities for lending, crediting, and liquidity by promoting peer-to-peer economics and
                  community governance. Our ecosystem consists of:
                </p>
                <ol className="text-left list-decimal space-y-2 pl-4">
                  <li>
                    <h6 className="font-bold">KITSU P2E</h6>
                    <p>
                      KITSU Play to Earn was designed to be both easy and fun game P2E. By making KITSU token more useful in the Play-to-Earn space, we can
                      improve the gameplay and make the level a way to a profitable activity
                    </p>
                  </li>
                  <li>
                    <h6 className="font-bold">NFT Marketplace</h6>
                    <p>
                      We will develop exciting features that will encourage long-term engagement and interaction with the KITSU platform, enabling the community
                      to make transactions such as buying and selling items using KITSU token, including clothing and characters, as well as NFT characters and
                      KITSU stones.
                    </p>
                  </li>
                  <li>
                    <h6 className="font-bold">KITSU NFT</h6>
                    <p>
                      KITSU will also work on NFT projects that give members special perks, such as access to the community of game developers, the ability to
                      represent and trade in-game characters and cars, and the ability to buy and sell goods and services that are only available to NFT
                      holders.
                    </p>
                  </li>
                </ol>
              </div>

              <div className="space-y-4 my-8">
                <h4 className="font-bold text-lg">KITSU's Token’s Use Case</h4>
                <div className="grid grid-cols-3 gap-6">
                  <div className="w-full border border-white p-4 flex justify-center items-center rounded">Governance</div>
                  <div className="w-full border border-white p-4 flex justify-center items-center rounded">Purchase in-game assets</div>
                  <div className="w-full border border-white p-4 flex justify-center items-center rounded">Upload assets to the marketplace</div>
                  <div className="w-full border border-white p-4 flex justify-center items-center rounded">Passive revenues through staking</div>
                  <div className="w-full border border-white p-4 flex justify-center items-center rounded">Passive revenues through farming</div>
                  <div className="w-full border border-white p-4 flex justify-center items-center rounded">Play and earn</div>
                </div>
              </div>

              <div className="space-y-4">
                <p>
                  Staking is our way of rewarding our community members for having a long-term mindset and locking up their KITSU tokens. Staking your KITSU
                  enables you to collect KITSU rewards when you lock your tokens on the staking dashboard. In the future, KITSU staking will grant you voting
                  rights and a say over the use of the Community Treasury.
                </p>
                <p>
                  The Community Treasury will start receiving revenue generated by KITSU as well as a portion of the staking rewards. The treasury will be
                  governed by KITSU stakeholders once the network becomes sufficiently decentralized. The Treasury receives inflows from all KITSU Marketplace
                  transactions. More streams will be added in the future from more sources as new opportunities arise.
                </p>
                <p>We see KITSU GAME as being a game that is truly owned and operated by the community that plays it.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="roadmap" className="w-full bg-brown relative bg-cover bg-top pb-96 overflow-hidden" style={{ backgroundImage: `url(${ImgBackgroundRoadmap})` }}>
        <img src={ImgDividerTopRoadmap} alt="Background" className="w-full absolute inset-x-0 top-0" />
        <img src={ImgDividerBottomRoadmap} alt="Background" className="w-full absolute inset-x-0 bottom-0" />
        <img src={ImgRoadmapPlanet2} alt="Planet" className="w-80 absolute left-1/2 top-1/4 animate-meteor" />
        <img src={ImgRoadmapRoad} alt="Road" className="w-full absolute top-64 hidden md:block" />
        <img src={ImgRoadmapPlanet1} alt="Planet" className="w-56 absolute left-20 top-24 animate-meteor" />
        <img src={ImgRoadmapPlane} alt="Plane" className="w-96 absolute left-20 top-52" />
        <img src={ImgRoadmapPlanet3} alt="Planet" className="w-96 absolute right-40 bottom-12 animate-meteor" />
        <img src={ImgRoadmapMonth2} alt="Month" className="w-16 absolute right-56 bottom-20 animate-meteor" />

        <div className="relative max-w-2xl mx-auto px-4 -top-12 md:-top-20">
          <div className="relative">
            <img src={ImgTitleRoadmap} alt="Roadmap" className="w-full mix-blend-screen" data-aos="fade-in" />
            <div className="absolute top-16 sm:top-20 md:top-32 w-full">
              <img src={ImgTitleTextRoadmap} alt="Roadmap" className="w-44 sm:w-52 md:w-64 mx-auto" data-aos="zoom-in" data-aos-delay="200" />
            </div>
          </div>
        </div>

        <div className="px-8 font-dosis">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 lg:gap-24 2xl:gap-48">
            <div>
              <div className="relative mb-12" data-aos="zoom-in">
                <img src={ImgRoadmapBgTitle} alt="" className="absolute -top-8 inset-x-0 mx-auto w-60 mix-blend-screen" />
                <div className="relative text-center">
                  <h2 className="text-primary text-6xl">Q3</h2>
                  <h3 className="text-white text-2xl">2022</h3>
                </div>
              </div>
              <div
                className="relative w-full bg-gradient-to-b from-[#008E72] px-5 pt-10 pb-16 text-white text-center"
                data-aos="fade-down"
                data-aos-delay="200"
              >
                <img src={ImgRoadmapList} alt="" className="absolute inset-x-0 -top-4 w-full" />
                <p className="text-lg leading-relaxed">
                  KITSU Token and Game Project Initiation <br />
                  Social Media Initial Engagement and Creation <br />
                  IDO Private sale and Presale Announcement <br />
                  IGO Private sale and Presale Announcement <br />
                  Website and Whitepaper Launching <br />
                  IDO Private sale and presale <br />
                  DEX listing on Pancakeswap
                </p>
              </div>
            </div>
            <div className="xl:mt-[32rem] 2xl:mt-[44rem] xl:mr-12">
              <div className="relative mb-12" data-aos="zoom-in">
                <img src={ImgRoadmapBgTitle} alt="" className="absolute -top-8 inset-x-0 mx-auto w-60 mix-blend-screen" />
                <div className="relative text-center">
                  <h2 className="text-primary text-6xl">Q4</h2>
                  <h3 className="text-white text-2xl">2022</h3>
                </div>
              </div>
              <div
                className="relative w-full bg-gradient-to-b from-[#008E72] px-5 pt-10 pb-16 text-white text-center"
                data-aos="fade-down"
                data-aos-delay="200"
              >
                <img src={ImgRoadmapList} alt="" className="absolute inset-x-0 -top-4 w-full" />
                <p className="text-lg leading-relaxed">
                  IGO Private sale and presale <br />
                  Global Community Development <br />
                  KITSU Game Beta Release <br />
                  KITSU NFT Launch <br />
                  Coinmarketcap and Coingecko Listing <br />
                  Partnership and Marketing Strategy
                </p>
              </div>
            </div>
            <div className="xl:mt-[40rem] 2xl:mt-[52rem]">
              <div className="block xl:hidden relative mb-12">
                <img src={ImgRoadmapBgTitle} alt="BG Title" className="absolute -top-8 inset-x-0 mx-auto w-60 mix-blend-screen" />
                <div className="relative text-center">
                  <h2 className="text-primary text-6xl">Q1</h2>
                  <h3 className="text-white text-2xl">2023</h3>
                </div>
              </div>
              <div
                className="relative w-full bg-gradient-to-b xl:bg-gradient-to-t from-[#008E72] px-5 pt-10 pb-16 text-white text-center"
                data-aos="fade-down"
                data-aos-delay="200"
              >
                <img src={ImgRoadmapBgTitle} alt="BG Title" className="block xl:hidden absolute inset-x-0 -top-4 w-full" />
                <p className="text-lg leading-relaxed">
                  KITSU Game Public Release <br />
                  KITSU P2E and NFT Game Integration Release <br />
                  KITSU Game Event and Competition <br />
                  100.000 downloads target for KITSU Game <br />
                  Top 100 CEX listing
                </p>
                <img src={ImgRoadmapBgTitle} alt="BG Title" className="hidden xl:block absolute inset-x-0 -bottom-4 w-full" />
              </div>
              <div className="hidden xl:block relative mt-12">
                <img src="./img/roadmap/bg-title.webp" alt="" className="absolute -top-8 inset-x-0 mx-auto w-60 mix-blend-screen" />
                <div className="relative text-center">
                  <h2 className="text-primary text-6xl">Q1</h2>
                  <h3 className="text-white text-2xl">2023</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="paper" className="w-full bg-primary relative bg-cover bg-top" style={{ backgroundImage: `url(${ImgBackgroundPaper})` }}>
        <img src={ImgDividerBottomPaper} alt="Background" className="w-full absolute inset-x-0 bottom-0" />

        <div className="relative max-w-2xl mx-auto px-4 -top-12 md:-top-20 text-center">
          <div className="relative">
            <img src={ImgTitlePaper} alt="Paper" className="w-full mix-blend-screen" data-aos="fade-in" />
            <div className="absolute top-20 sm:top-24 md:top-36 w-full">
              <img src={ImgTitleTextPaper} alt="Paper" className="w-48 sm:w-64 md:w-72 mx-auto" data-aos="zoom-in" data-aos-delay="200" />
            </div>
          </div>

          <div className="relative">
            <img src={ImgPaperHolo} alt="Holo" className="w-full mix-blend-lighten" data-aos="fade-in" />
            <img src={ImgPaperCover} alt="Paper" className="w-56 sm:w-72 md:w-80 absolute inset-0 mx-auto top-8" data-aos="slide-up" data-aos-delay="200" />
          </div>

          <a
            href="https://simplebooklet.com/kitsublackpaper"
            target="_blank"
            className="relative inline-block font-barlow font-medium uppercase text-2xl bg-brown px-16 py-4 rounded-xl border-x-4 border-l-neonblue border-r-neonpink group transition-all hover:contrast-125 hover:border-x-primary hover:-translate-y-1 hover:shadow-xl hover:shadow-neonpink/50"
            rel="noreferrer"
          >
            <span className="absolute z-0 inset-x-0 -translate-x-px text-neonblue group-hover:-translate-x-0">Download</span>
            <span className="absolute z-0 inset-x-0 translate-x-px text-neonpink group-hover:translate-x-0">Download</span>
            <span className="relative z-10 text-white group-hover:text-neonblue">Download</span>
          </a>
        </div>
      </div>

      <div
        id="tokenomic"
        className="w-full bg-night pt-40 md:pt-20 pb-20 md:pb-0 relative bg-contain bg-no-repeat"
        style={{ backgroundImage: `url(${ImgBackgroundTokenomic})` }}
      >
        <div className="relative max-w-2xl mx-auto px-4 text-center">
          <div className="relative px-8 py-16 text-white font-dosis text-xl bg-no-repeat bg-cover" style={{ backgroundImage: `url(${ImgTokenomicHoloToken})` }}>
            <div className="flex justify-center items-center space-x-10">
              <span>Ticker</span>
              <strong>KITSU</strong>
            </div>
            <div className="flex justify-center items-center space-x-10">
              <span>Name</span>
              <strong>KITSU</strong>
            </div>

            <h6 className="mt-8 mb-4 font-bold">Wallet (SOL)</h6>

            <div className="space-y-2">
              <div className="grid grid-cols-2 gap-2">
                <h6 className="opacity-60 text-right">Supply</h6>
                <p className="text-left">20.000.000.000</p>
              </div>

              <div className="grid grid-cols-2 gap-2">
                <h6 className="opacity-60 text-right">Blockchain Network</h6>
                <p className="text-left">Solana (SOL)</p>
              </div>

              <div className="grid grid-cols-2 gap-2">
                <h6 className="opacity-60 text-right">Smart Contract Address</h6>
                <p className="text-left break-words">G4Z1GuJEwoSFmEfEgWZhHUYkCWk249wKughJJpK3UsWY</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6 mt-8 md:hidden">
              <div className="border-4 border-white/30 rounded-full w-36 h-36 flex flex-col items-center justify-center mx-auto p-2">
                <h6 className="text-primary font-semibold text-2xl">25%</h6>
                <p className="text-xs">Play to Earn, NFT, and Game Development</p>
              </div>
              <div className="border-4 border-white/30 rounded-full w-36 h-36 flex flex-col items-center justify-center mx-auto p-2">
                <h6 className="text-primary font-semibold text-2xl">10%</h6>
                <p className="text-xs">Partnership</p>
              </div>
              <div className="border-4 border-white/30 rounded-full w-36 h-36 flex flex-col items-center justify-center mx-auto p-2">
                <h6 className="text-primary font-semibold text-2xl">15%</h6>
                <p className="text-xs">Liquidity</p>
              </div>
              <div className="border-4 border-white/30 rounded-full w-36 h-36 flex flex-col items-center justify-center mx-auto p-2">
                <h6 className="text-primary font-semibold text-2xl">10%</h6>
                <p className="text-xs">Team and Advisor Allocations</p>
              </div>
              <div className="border-4 border-white/30 rounded-full w-36 h-36 flex flex-col items-center justify-center mx-auto p-2">
                <h6 className="text-primary font-semibold text-2xl">15%</h6>
                <p className="text-xs">Staking and Farming</p>
              </div>
              <div className="border-4 border-white/30 rounded-full w-36 h-36 flex flex-col items-center justify-center mx-auto p-2">
                <h6 className="text-primary font-semibold text-2xl">15%</h6>
                <p className="text-xs">Private and Public Sale</p>
              </div>
              <div className="border-4 border-white/30 rounded-full w-36 h-36 flex flex-col items-center justify-center mx-auto p-2">
                <h6 className="text-primary font-semibold text-2xl">10%</h6>
                <p className="text-xs">Sales and Marketing</p>
              </div>
            </div>

            <div className="absolute inset-x-0 -top-48 md:top-96">
              <img src={ImgTitleTokenomic} alt="Tokenomic" className="w-full mix-blend-screen" data-aos="fade-in" />
              <div className="absolute top-20 sm:top-28 md:top-36 w-full">
                <img src={ImgTitleTextTokenomic} alt="Tokenomic" className="w-44 sm:w-56 md:w-72 mx-auto" data-aos="zoom-in" data-aos-delay="200" />
              </div>
            </div>
          </div>
        </div>

        <div className="container px-4 hidden md:block">
          <img src={ImgTokenomicHoloText} alt="Holo" className="w-full -mt-72" />
        </div>
      </div>

      <div id="team" className="w-full bg-brown relative bg-cover bg-top pb-24 font-dosis" style={{ backgroundImage: `url(${ImgBackgroundTeam})` }}>
        <img src={ImgDividerTopTeam} alt="Background" className="w-full absolute inset-x-0 top-0" />

        <div className="relative max-w-2xl mx-auto px-4 -top-12 text-center">
          <div className="relative">
            <img src={ImgTitleTeam} alt="Team" className="w-full mix-blend-screen" data-aos="fade-in" />
            <div className="absolute top-20 sm:top-24 md:top-36 w-full">
              <img src={ImgTitleTextTeam} alt="Team" className="w-24 sm:w-32 md:w-40 mx-auto" data-aos="zoom-in" data-aos-delay="200" />
            </div>

            <div className="text-white text-center mb-8 -mt-10 md:-mt-20">
              <h2 className="text-3xl md:text-5xl">FOUNDING</h2>
              <h3 className="text-xl md:text-3xl">Member</h3>
            </div>
          </div>
        </div>

        <div className="max-w-2xl mx-auto px-4 mb-16">
          <div className="grid grid-cols-2 gap-8 text-center">
            <div>
              <img src={ImgTeamListSingle} alt="List" className="w-full h-8 mb-8" />
              <div className="p-4 space-y-2">
                <img src={ImgTeamKenshin} alt="Kenshin" className="h-36 mx-auto" />
                <h4 className="text-blood text-xl font-semibold">Kenshin</h4>
                <p className="text-white">
                  Kenshin, is one of project founder. He geek related to the world of technology, the metaverse and games. His hobbies are playing games until
                  he forgets everything, forgets to eat, drink, sleep and even forgets his own name. He believe life start at 40.
                </p>
              </div>
            </div>
            <div>
              <img src={ImgTeamListSingle} alt="List" className="w-full h-8 mb-8" />
              <div className="p-4 space-y-2">
                <img src={ImgTeamKitsuLover} alt="Kitsu Lover" className="h-36 mx-auto" />
                <h4 className="text-blood text-xl font-semibold">Kitsu Lover</h4>
                <p className="text-white">
                  Hi I am Kitsu Lover, one of project founder too. I love playing games especialy blockchain games. If i can start pure 5 step ahead from my
                  arrival, i am sure i can beat you all
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-6xl mx-auto px-4">
          <div className="text-white text-center mb-8">
            <h2 className="text-3xl md:text-5xl">KITSU</h2>
            <h3 className="text-xl md:text-3xl">Team</h3>
          </div>

          <img src={ImgTeamListDouble} alt="List" className="w-full h-8 mb-8" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex space-x-4 p-4">
              <div className="flex-shrink-0">
                <img src={ImgTeamKitsuMonster} alt="Kitsu Monster" className="w-28" />
              </div>
              <div className="space-y-2">
                <h4 className="text-blood text-xl font-semibold">Kitsu Monster</h4>
                <p className="text-white">
                  Kitsu Monster strongly believes blockchain gaming will 'inevitably' examine, challenge and demystify the ideas behind the emerging industry by
                  speaking to the people who live and breathe it.
                </p>
              </div>
            </div>
            <div className="flex space-x-4 p-4">
              <div className="flex-shrink-0">
                <img src={ImgTeamKitsushi} alt="Kit Su Shi" className="w-28" />
              </div>
              <div className="space-y-2">
                <h4 className="text-blood text-xl font-semibold">Kit Su Shi</h4>
                <p className="text-white">
                  Kit Su Shi is a Blockchain enthusiast and loves to play Mobile Gaming. He has many experiences in Blockchain industry and NFT industry and
                  Gaming industry as well
                </p>
              </div>
            </div>
            <div className="flex space-x-4 p-4">
              <div className="flex-shrink-0">
                <img src={ImgTeamKitsune} alt="Sensei Golden Tail Kitsune" className="w-28" />
              </div>
              <div className="space-y-2">
                <h4 className="text-blood text-xl font-semibold">Sensei Golden Tail Kitsune</h4>
                <p className="text-white">
                  Though seemingly playful and fond of pranks, Golden-Tail considers the blockchain world very seriously. Spending lots of years under guidance
                  of Nine Tails, the highest kitsune blockchain expert . When he returned, he did not say a word, but redoubled his efforts to train his kin in
                  the art of the blockchain.
                </p>
              </div>
            </div>
            <div className="flex space-x-4 p-4">
              <div className="flex-shrink-0">
                <img src={ImgTeamKitsuSpirit} alt="Kitsu Spirit" className="w-28" />
              </div>
              <div className="space-y-2">
                <h4 className="text-blood text-xl font-semibold">Kitsu Spirit</h4>
                <p className="text-white">Multi-award winner of various competitions. Kind of spiritual things.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="games" className="w-full bg-skylight relative bg-cover bg-top pb-20">
        <div className="border-b-[5rem] border-l-[5rem] border-l-brown border-b-transparent absolute top-0 left-0"></div>
        <div className="border-t-[5rem] border-r-[5rem] border-r-brown border-t-transparent absolute bottom-0 right-0"></div>

        <div className="relative max-w-2xl mx-auto px-4 text-center">
          <div className="relative">
            <img src={ImgTitleDownload} alt="Download" className="w-full mix-blend-screen" data-aos="fade-in" />
            <div className="absolute top-16 sm:top-20 md:top-28 w-full">
              <img src={ImgTitleTextDownload} alt="Download" className="w-36 sm:w-44 md:w-64 mx-auto" data-aos="zoom-in" data-aos-delay="200" />
            </div>
          </div>
        </div>

        <div className="container px-4">
          <div className="grid grid-cols-3 gap-8 mb-8">
            <div className="col-span-3 md:col-span-2">
              <img src={ImgGamesPhone} alt="Phone" className="w-full -ml-0 md:-ml-16" />
            </div>
            <div className="col-span-3 md:col-span-1 font-dosis text-center">
              <h2 className="uppercase text-5xl tracking-wider mb-4">Kitsu</h2>
              <p>
                Kitsu Play-and-Earn connects a worldwide community of gamers with highly engaging gameplay. We build an exclusive virtual world where players
                can own, play, and monetize their gameplay experience using Kitsu, the platform's core utility token.
              </p>
              <div className="space-y-6 mt-6">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.kitsu.gokart.racing"
                  className="block hover:scale-105 transition-all"
                  rel="noreferrer"
                >
                  <img src={ImgGamesBtnPlayStore} alt="Play Store" className="h-16 mx-auto" data-aos="zoom-in" data-aos-delay="200" />
                </a>
              </div>
            </div>
          </div>

          <div className="py-12 md:py-24 bg-cover bg-center relative" style={{ backgroundImage: `url(${ImgGamesBgSplide})` }}>
            <div className="bg-gradient-to-r from-skylight w-20 h-full absolute left-0 top-0 z-10"></div>
            <div className="bg-gradient-to-l from-skylight w-20 h-full absolute right-0 top-0 z-10"></div>

            <Splide
              hasTrack={false}
              aria-label="Games Screenshot"
              options={{
                type: "loop",
                padding: "20rem",
                gap: "2rem",
                autoplay: true,
                breakpoints: {
                  480: {
                    padding: "2rem",
                    gap: "0.5rem",
                  },
                  640: {
                    padding: "6rem",
                    gap: "1rem",
                  },
                  768: {
                    padding: "10rem",
                    gap: "1.5rem",
                  },
                  1024: {
                    padding: "14rem",
                  },
                },
              }}
            >
              <SplideTrack>
                <SplideSlide>
                  <img src={ImgGamesSC1} className="w-full" alt="Screenshot 1" />
                </SplideSlide>
                <SplideSlide>
                  <img src={ImgGamesSC2} className="w-full" alt="Screenshot 2" />
                </SplideSlide>
                <SplideSlide>
                  <img src={ImgGamesSC3} className="w-full" alt="Screenshot 3" />
                </SplideSlide>
                <SplideSlide>
                  <img src={ImgGamesSC4} className="w-full" alt="Screenshot 4" />
                </SplideSlide>
                <SplideSlide>
                  <img src={ImgGamesSC5} className="w-full" alt="Screenshot 5" />
                </SplideSlide>
                <SplideSlide>
                  <img src={ImgGamesSC6} className="w-full" alt="Screenshot 6" />
                </SplideSlide>
              </SplideTrack>

              <div className="splide__arrows">
                <button className="splide__arrow splide__arrow--prev absolute inset-y-0 my-auto left-0 top-0 z-10">
                  <img src={ImgGamesArrowL} alt="Prev." className="w-20 md:w-28 relative z-10 hover:contrast-75 hover:-translate-x-1 transition-all" />
                </button>
                <button className="splide__arrow splide__arrow--next absolute inset-y-0 my-auto right-0 top-0 z-10">
                  <img src={ImgGamesArrowR} alt="Next." className="w-20 md:w-28 relative z-10 hover:contrast-75 hover:translate-x-1 transition-all" />
                </button>
              </div>
            </Splide>
          </div>
        </div>
      </div>

      <div id="ecosystem" className="pb-20 w-full bg-brown relative bg-cover bg-top" style={{ backgroundImage: `url(${ImgBackgroundEcosystem})` }}>
        <img src={ImgDividerBottomEcosystem} alt="Background" className="w-full absolute inset-x-0 bottom-0" />

        <div className="relative max-w-2xl mx-auto px-4 -top-14 text-center">
          <div className="relative">
            <img src={ImgTitleEcosystem} alt="Ecosystems" className="w-full mix-blend-screen" data-aos="fade-in" />
            <div className="absolute top-20 sm:top-28 md:top-36 w-full">
              <img src={ImgTitleTextEcosystem} alt="Ecosystems" className="w-36 sm:w-44 md:w-64 mx-auto" data-aos="zoom-in" data-aos-delay="200" />
            </div>
          </div>
          <div className="text-white space-y-4">
            <p>
              KITSU is a utility token built on the Solana (SOL) blockchain protocol, which acts as the native token and medium of exchange in the KITSU
              ecosystem.
            </p>
            <p>
              This ecosystem is key to unlocking more economic opportunities for lending, crediting, and liquidity by promoting peer-to-peer economics and
              community governance. Our ecosystem consists of:
            </p>
          </div>
        </div>
        <div className="max-w-4xl mx-auto">
          <img src={ImgEcosystem} alt="Exosystem" className="w-full mix-blend-screen" data-aos="fade-in" />
        </div>
      </div>

      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => setShowModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="div" className="text-2xl font-bold text-gray-900 mb-4 flex justify-between items-center">
                    <h3>Announcement</h3>
                    <div className="cursor-pointer z-50" onClick={() => setShowModal(false)}>
                      <svg className="fill-current text-black w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                      </svg>
                    </div>
                  </Dialog.Title>

                  <img src={ImgPresale} alt="Presale" className="w-full rounded-xl" />

                  <div className="flex justify-center space-x-2 mt-4">
                    <a
                      href="https://discord.gg/thekitsu"
                      target="_blank"
                      className="flex items-center space-x-2 px-4 bg-[#5866f2] p-3 rounded-lg text-white hover:contrast-150"
                      rel="noreferrer"
                    >
                      <Icon icon="fa6-brands:discord" className="iconify w-5 h-5" />
                      <span>Discord</span>
                    </a>
                    <a
                      href="https://twitter.com/thekitsugame"
                      target="_blank"
                      className="flex items-center space-x-2 px-4 bg-[#1d9cf0] p-3 rounded-lg text-white hover:contrast-150"
                      rel="noreferrer"
                    >
                      <Icon icon="fa6-brands:twitter" className="iconify w-5 h-5" />
                      <span>Twitter</span>
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </LayoutBase>
  );
}

export default LandingPage;
