import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import ImgBackgroundPaper from "../assets/images/bg/paper.webp";
import LoadingSpinner from "../components/LoadingSpinner";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";
import { usePresaleUtil } from "../utils/web3/PresaleUtil";
import { isMobile } from "react-device-detect";

function PresalePage() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [metamaskAddress, setMetamaskAddress] = useState();
  const [solAddress, setSolAddress] = useState("");
  const { internalAPIClient, internalAPIClientAbortController } = useAuthenticatedHttpClient();
  const { getUSDTBalance, transferUSDT, signPersonalMessage } = usePresaleUtil();

  useEffect(() => {
    const cachedMMAddress = localStorage.getItem("metask_address");
    if (cachedMMAddress) setMetamaskAddress(cachedMMAddress);
    else return;

    // if currentUser is not set, hit GET /user api to fetch info
    if (cachedMMAddress && currentUser == null) {
      internalAPIClient
        .get(`/user/${cachedMMAddress}`)
        .then((res) => {
          const userData = res?.data?.data;
          if (userData) {
            localStorage.setItem("x-user-kitsu", JSON.stringify(userData));
            setCurrentUser(userData);

            if (userData?.name && userData?.email && userData?.solana_wallet) return;
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Sign-In Error: Failed to fetch user");
          localStorage.removeItem("metask_address");
          return;
        });
    }

    setSolAddress(currentUser?.solana_wallet);
    //if incomplete profile, navigate to /edit-profile
    if (currentUser?.name === null || currentUser?.email === null || currentUser?.solana_wallet === null) {
      return navigate("/edit-profile");
    }
  }, [currentUser, metamaskAddress, navigate, setMetamaskAddress, setSolAddress, setCurrentUser, internalAPIClient]);

  const handleConnectMetamask = async () => {
    setIsLoading(true);

    if (!window.ethereum) {
      setIsLoading(false);
      toast.error("Install metamask extension!");
      return;
    }

    let accounts;
    try {
      accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    } catch (e) {
      console.log(e);
      toast.error("Failed to connect to wallet");
      setIsLoading(false);
      return;
    }

    const account = accounts[0];
    console.log(accounts[0]);

    // Initiate auth to get nonce
    let nonce = 0;
    try {
      nonce = await internalAPIClient.get(`/web3/auth?bsc_wallet=${accounts[0]}`);
      nonce = nonce?.data?.data?.nonce;
    } catch (e) {
      setIsLoading(false);
      toast.error("Authentication Error: " + e);
    }

    // Prepare authentication signature
    let signature;
    try {
      signature = await signPersonalMessage(`AUTH-KITSU:${accounts[0]}:${nonce + 1}`, account);
    } catch (e) {
      console.log(e);
      toast.error("Failed to sign authentication message!");
      setIsLoading(false);
      return;
    }
    console.log(signature);

    // Do web3 auth
    let verify;
    try {
      verify = await internalAPIClient.post("/web3/verify", {
        bsc_wallet: account,
        signature: signature,
      });
    } catch (e) {
      setIsLoading(false);
      toast.error("Authentication Error: " + e);
    }

    localStorage.setItem("metask_address", accounts);
    setMetamaskAddress(account);
    setIsLoading(false);
    window.location.reload();
  };

  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    let transaction;

    // Do web3 transfer
    try {
      transaction = await transferUSDT({
        fromWallet: metamaskAddress,
        toWallet: process.env[`REACT_APP_KITSU_DEV_WALLET_${process.env.REACT_APP_BSC_NETWORK_TYPE}`],
        amount: values.amount,
      });
    } catch (e) {
      console.log(e);
      toast.error("Failed to transfer USDT");
      setIsLoading(false);
      return;
    }

    // Submit transaction data to BE
    let submitPresale;
    try {
      submitPresale = await internalAPIClient.post("/presale", {
        // "sender": metamaskAddress,
        trx_hash: transaction,
      });
    } catch (e) {
      toast.error("Data Submission Failed!.\nContact Admin if your wallet balance is deducted!");
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    toast.success("Transaction Succeed");
  };

  const handleDisconnect = () => {
    localStorage.removeItem("x-user-kitsu");
    localStorage.removeItem("x-auth-kitsu");
    localStorage.removeItem("metask_address");
    setCurrentUser(null);
    window.location.reload();
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <div id="paper" className="w-full min-h-screen bg-primary relative bg-cover bg-top" style={{ backgroundImage: `url(${ImgBackgroundPaper})` }}>
        <div className={`max-w-xl mx-auto px-4 py-12 ${metamaskAddress && currentUser ? "py-8" : "py-32"}`}>
          <h1 className="text-white font-barlow text-4xl font-bold text-center">Kitsu Token Presale</h1>

          {metamaskAddress && currentUser ? (
            <div className="mt-8">
              <ul className="relative list-disc pl-8 my-4 border border-brown border-dashed px-4 pb-4 pt-10 rounded-lg">
                <li>
                  USDT (BEP20) Address:
                  <br />
                  <strong className="break-words">{metamaskAddress}</strong>
                </li>
                <li>
                  SOL Address:
                  <br />
                  <strong className="break-words">{solAddress}</strong>
                </li>

                <button
                  className="absolute top-0 left-0 bg-blood rounded-tl-lg rounded-br-lg px-3 py-1.5 font-bold text-xs uppercase tracking-wider text-white hover:bg-red-600"
                  onClick={handleDisconnect}
                >
                  Disconnect
                </button>
              </ul>

              <Formik
                initialValues={{ amount: "" }}
                validationSchema={Yup.object({
                  amount: Yup.number("This field must be a number").min(process.env.REACT_APP_MIN_BEP20_AMOUNT).required(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  handleOnSubmit(values);
                  setSubmitting(false);
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="w-full space-y-1">
                      <label htmlFor="amount">Total Amount (USDT)</label>
                      <Field type="text" name="amount" className="w-full rounded-md" />
                      <ErrorMessage name="amount" component="div" className="text-red-700" />
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="relative inline-block font-barlow font-medium uppercase text-2xl bg-brown text-white w-full p-3 rounded-xl border-x-4 border-l-neonblue border-r-neonpink group transition-all hover:contrast-125 hover:border-x-primary hover:-translate-y-1 hover:shadow-xl hover:shadow-neonpink/50"
                    >
                      Submit
                    </button>
                  </form>
                )}
              </Formik>

              <div className="mt-10">
                <div className="text-center mb-4">
                  <h3 className="font-barlow font-bold text-3xl text-brown">Tier List</h3>
                  <p className="text-brown/60">(Min. Amount - 30 USDT)</p>
                </div>

                <table className="w-full rounded-lg">
                  <thead className="text-white rounded-lg">
                    <tr className="">
                      <th className="bg-brown/50 text-left px-3 py-1 rounded-l-lg">No.</th>
                      <th className="bg-brown/50 text-left px-3 py-1">Amount</th>
                      <th className="bg-brown/50 text-left px-3 py-1 rounded-r-lg">Multiplier</th>
                    </tr>
                  </thead>
                  <tbody className="font-bold text-brown">
                    <tr>
                      <td className="px-3 py-0">#1</td>
                      <td className="px-3 py-0">{`<60`}</td>
                      <td className="px-3 py-0">55</td>
                    </tr>
                    <tr>
                      <td className="px-3 py-0">#2</td>
                      <td className="px-3 py-0">{`<300`}</td>
                      <td className="px-3 py-0">57.5</td>
                    </tr>
                    <tr>
                      <td className="px-3 py-0">#3</td>
                      <td className="px-3 py-0">{`<3,000`}</td>
                      <td className="px-3 py-0">60</td>
                    </tr>
                    <tr>
                      <td className="px-3 py-0">#4</td>
                      <td className="px-3 py-0">{`>=3,000`}</td>
                      <td className="px-3 py-0">65</td>
                    </tr>
                  </tbody>
                </table>

                <div className="text-brown mt-4">
                  <p>Example: 2,500 USDT</p>
                  <p className="font-bold">2,500 x 60 = 150,000 Kitsu Token</p>
                </div>
              </div>

              {/* <div className="mt-10">
                <div className="text-center mb-4">
                  <h3 className="font-barlow font-bold text-3xl text-brown">History</h3>
                </div>

                <table className="w-full rounded-lg">
                  <thead className="text-white rounded-lg">
                    <tr className="">
                      <th className="bg-brown/50 text-left px-3 py-1 rounded-l-lg">No.</th>
                      <th className="bg-brown/50 text-left px-3 py-1">Date</th>
                      <th className="bg-brown/50 text-left px-3 py-1">Transaction Hash</th>
                      <th className="bg-brown/50 text-left px-3 py-1 rounded-r-lg">Amount</th>
                    </tr>
                  </thead>
                  <tbody className="font-bold text-brown">
                    <tr>
                      <td colSpan="4" className="px-3 py-1 text-center">
                        Data not found.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          ) : (
            <>
              <div className="border-2 border-dashed border-brown rounded-lg p-4 mt-4 mb-8">
                <p className="font-bold text-lg text-brown/80">Steps to Buy Kitsu Token</p>
                <ol className="list-decimal pl-4 text-brown/60">
                  <li>Make sure you wallet is set to BNB Smart Chain Mainnet.</li>
                  <li>Connect your Wallet and Sign an Authentication String with Wallet.</li>
                  <li>Complete Your Profile by filling the Registration Form if you are a first-timer.</li>
                  <li>
                    Transfer USDT (BEP20) to Dev's Wallet <strong>{process.env[`REACT_APP_KITSU_DEV_WALLET_${process.env.REACT_APP_BSC_NETWORK_TYPE}`]}</strong>{" "}
                    (Min 30 USDT) from your BEP20 wallet.
                  </li>
                  <li>Voila!, You will auto-magically receive Kitsu Token in your Phantom Wallet 1 month after the Token Sales ended.</li>
                </ol>
              </div>
              <div className="space-y-2">
                {!window.ethereum && isMobile ? (
                  <div className="space-y-3">
                    <a
                      href="https://metamask.app.link/dapp/https://kitsu-mainnet.pages.dev/presale"
                      className={`relative text-center block font-barlow font-medium text-2xl text-white w-full p-3 rounded-xl border-x-4 border-l-neonblue border-r-neonpink group transition-all hover:contrast-125 hover:border-x-primary hover:-translate-y-1 hover:shadow-xl hover:shadow-neonpink/50 bg-brown`}
                    >
                      "Sign-In With Metamask"
                    </a>
                    <a
                      href="https://link.trustwallet.com/open_url?coin_id=56&url=kitsu-mainnet.pages.dev/presale"
                      className={`relative text-center block font-barlow font-medium text-2xl text-white w-full p-3 rounded-xl border-x-4 border-l-neonblue border-r-neonpink group transition-all hover:contrast-125 hover:border-x-primary hover:-translate-y-1 hover:shadow-xl hover:shadow-neonpink/50 bg-brown`}
                    >
                      "Sign-In With TrustWallet"
                    </a>
                  </div>
                ) : (
                  <button
                    onClick={handleConnectMetamask}
                    className={`relative inline-block font-barlow font-medium text-2xl text-white w-full p-3 rounded-xl border-x-4 border-l-neonblue border-r-neonpink group transition-all hover:contrast-125 hover:border-x-primary hover:-translate-y-1 hover:shadow-xl hover:shadow-neonpink/50 bg-brown`}
                  >
                    "Sign-In With Wallet"
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <Toaster />
    </>
  );
}

export default PresalePage;
